import logo from './logoc.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Welcome to $CMT Community
        </p>
        <a
          className="App-link"
          href="https://x.com/coinamrktoken"
          target="_blank"
          rel="noopener noreferrer"
        >
          Official Twitter
        </a>
      </header>
    </div>
  );
}

export default App;
